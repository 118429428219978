<template>
  <main>
    <section
      class="page__title-area pt-160 pb-165 p-relative page__title-overlay"
      style="background-image: url('assets/img/page-title/page-title-1.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="page__title-wrapper">
              <h3 class="page__title">Products</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Products
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__area pt-125 pb-100 p-relative z-index-1">
      <div class="container">
        <div class="row">
          <div
            class="
              col-xxl-6
              offset-xxl-3
              col-xl-8
              offset-xl-2
              col-lg-8
              offset-lg-2
            "
          >
            <div class="section__title-wrapper-2 mb-70 text-center">
              <span class="section__title-pre section__title-pre-2 center"
                >Safety Is Our First Priority</span
              >
              <h2 class="section__title section__title-2">
                Explore About Our <br />
                Products
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6"
            v-for="(product, i) in products"
            :key="'product' + i"
          >
            <div
              class="
                features__item-2 features__item-4
                black-bg-3
                text-center
                mb-30
              "
            >
              <div class="features__icon-2 mb-15">
                <span>
                  <img
                    :src="apiURL + '/' + product.image"
                    v-if="product.image"
                    alt="..."
                    class="img-fluid"
                  />
                  <img
                    :src="`${publicPath}img/placeholder-small.png`"
                    v-else
                    alt="..."
                    class="img-fluid"
                  />
                </span>
              </div>
              <h3 class="features__title features__title-2 white-color">
                <a href="javascript:void(0)">{{ product.title }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Products",
  data: () => ({
    products: [],
    isMobo: false,
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `ghost/products/list`)
      .then((response) => {
        if (response.data.status) {
          this.products = response.data.products;
        }
      });
  },
  created() {
    this.checkMobileEnv();
  },
  methods: {
    checkMobileEnv() {
      if (matchMedia) {
        const mq = window.matchMedia("(max-width: 768px)");
        this.isMobo = mq.matches;
        try {
          // Chrome & Firefox
          mq.addEventListener("change", () => {
            this.isMobo = mq.matches;
          });
        } catch (e1) {
          try {
            // Safari
            mq.addListener(() => {
              this.isMobo = mq.matches;
            });
          } catch (e2) {
            console.error(e2);
          }
        }
        //this.WidthChange(mq);
      }
    },
    WidthChange(mq) {
      this.isMobo = mq.matches;
    },
  },
};
</script>
<style scoped>
.features__icon-2 span {
  display: inline-block;
  width: 200px;
  height: 200px;
  line-height: 200px;
  background: #ffffff;
}
.features__item-2 {
  padding: 15px 15px;
}
.features__title {
  letter-spacing: 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 50px;
  -webkit-line-clamp: 2;
}
</style>
