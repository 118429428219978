<template>
  <main>
    <section
      class="page__title-area pt-160 pb-165 p-relative page__title-overlay"
      style="background-image: url('assets/img/page-title/page-title-1.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="page__title-wrapper">
              <h3 class="page__title">About Us</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about__area p-relative z-index-1 pt-130 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="about__thumb w-img mb-30">
              <img class="about-1" :src="apiURL + '/' + about.image" alt="" />
            </div>
          </div>
          <div class="col-xxl-5 col-xl-6 col-lg-6">
            <div class="about__wrapper-2 pl-20">
              <div class="section__title-wrapper-2 mb-25">
                <span class="section__title-pre section__title-pre-2"
                  >About Company</span
                >
                <h2 class="section__title section__title-2">
                  {{ about.sloga }}
                </h2>
              </div>
              <p>{{ about.whoWeAre }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="features__area py-5 grey-bg fix">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6 col-lg-6">
            <div class="features__wrapper-3 features__wrapper-border pr-45">
              <div class="section__title-wrapper mb-80 text-lg-end">
                <span class="section__title-pre section__title-pre-3 right"
                  >Who We Are</span
                >
                <h2 class="section__title section__title-2">Strategies</h2>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 offset-xxl-1 col-xl-6 col-lg-6">
            <div class="features__wrapper-3">
              <p>{{ about.strategy }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="features__area py-5 bg-white fix">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6 col-lg-6">
            <div class="features__wrapper-3 features__wrapper-border pr-45">
              <div class="section__title-wrapper mb-80 text-lg-end">
                <span class="section__title-pre section__title-pre-3 right"
                  >Who We Are</span
                >
                <h2 class="section__title section__title-2">Mission</h2>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 offset-xxl-1 col-xl-6 col-lg-6">
            <div class="features__wrapper-3">
              <p>{{ about.mission }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__area py-5 grey-bg fix">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6 col-lg-6">
            <div class="features__wrapper-3 features__wrapper-border pr-45">
              <div class="section__title-wrapper mb-80 text-lg-end">
                <span class="section__title-pre section__title-pre-3 right"
                  >Who We Are</span
                >
                <h2 class="section__title section__title-2">Vision</h2>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 offset-xxl-1 col-xl-6 col-lg-6">
            <div class="features__wrapper-3">
              <p>{{ about.vision }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "About",
   data: () => ({
    about: {},
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `ghost/frontends/list/about`)
      .then((response) => {
        if (response.data.status) {
          this.about = response.data.about[0];
        }
      });
  },
};
</script>
