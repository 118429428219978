<template>
  <main>
    <section
      class="page__title-area pt-160 pb-165 p-relative page__title-overlay"
      style="background-image: url('assets/img/page-title/page-title-1.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="page__title-wrapper">
              <h3 class="page__title">Gallery</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Gallery
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__area py-5 p-relative z-index-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Gallery</h2>
            <hr />
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6"
            v-for="(image, i) in gallery"
            :key="'image' + i"
          >
            <img
              :src="apiURL + '/' + image"
              class="img img-thumbnail rounded img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Gallery",
  data: () => ({
    gallery: [],
    isMobo: false,
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `ghost/frontends/list/gallery`)
      .then((response) => {
        this.gallery = response.data.row.data_values;
      });
  },
  created() {
    this.checkMobileEnv();
  },
  methods: {
    checkMobileEnv() {
      if (matchMedia) {
        const mq = window.matchMedia("(max-width: 768px)");
        this.isMobo = mq.matches;
        try {
          // Chrome & Firefox
          mq.addEventListener("change", () => {
            this.isMobo = mq.matches;
          });
        } catch (e1) {
          try {
            // Safari
            mq.addListener(() => {
              this.isMobo = mq.matches;
            });
          } catch (e2) {
            console.error(e2);
          }
        }
        //this.WidthChange(mq);
      }
    },
    WidthChange(mq) {
      this.isMobo = mq.matches;
    },
  },
};
</script>
<style scoped>
.features__icon-2 span {
  display: inline-block;
  width: 200px;
  height: 200px;
  line-height: 200px;
  background: #ffffff;
}
.features__item-2 {
  padding: 15px 15px;
}
.features__title {
  letter-spacing: 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 50px;
  -webkit-line-clamp: 2;
}
</style>
