<template>
  <main>
    <section
      class="page__title-area pt-160 pb-165 p-relative page__title-overlay"
      style="background-image: url('assets/img/page-title/page-title-1.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="page__title-wrapper">
              <h3 class="page__title">Services</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Services
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="
        services__area services__overlay
        p-relative
        z-index-11
        pt-125
        pb-55
      "
    >
      <div class="container">
        <div class="row">
          <div
            class="
              col-xxl-8
              offset-xxl-2
              col-xl-8
              offset-xl-2
              col-lg-10
              offset-lg-1
            "
          >
            <div class="section__title-wrapper mb-80 text-center">
              <span class="section__title-pre section__title-pre-2 center"
                >RELIABLE SERVICES</span
              >
              <h2 class="section__title section__title-2">
                We are Qualified &amp; Professional
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-4 col-xl-4 col-lg-6 col-md-6"
            v-for="(service, i) in services"
            :key="'service' + i"
          >
            <div class="services__item services__item-2 p-relative mb-30">
              <div class="services__thumb w-img">
                <img
                  :src="apiURL + '/' + service.image"
                  style="height: 246px"
                  alt=""
                />
              </div>
              <div class="services__content services__content-2 text-center">
                <div class="services__icon services__icon-2">
                  <img
                    class="img-fluid flaticon-paint-1"
                    :src="`${publicPath}img/logo-icon.png`"
                    alt=""
                  />
                </div>
                <h3 class="services__title">
                  <router-link :to="{ name: '' }">{{
                    service.title
                  }}</router-link>
                </h3>
                <p class="two-lines">{{ service.description }}</p>
                <router-link :to="{ name: '' }" class="link-btn-2">
                  read more
                  <i class="far fa-arrow-right"></i>
                  <i class="far fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Services",
  data: () => ({
    services: [],
    isMobo: false,
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `ghost/services/list`)
      .then((response) => {
        if (response.data.status) {
          this.services = response.data.services;
        }
      });
  },
  created() {
    this.checkMobileEnv();
  },
  methods: {
    checkMobileEnv() {
      if (matchMedia) {
        const mq = window.matchMedia("(max-width: 768px)");
        this.isMobo = mq.matches;
        try {
          // Chrome & Firefox
          mq.addEventListener("change", () => {
            this.isMobo = mq.matches;
          });
        } catch (e1) {
          try {
            // Safari
            mq.addListener(() => {
              this.isMobo = mq.matches;
            });
          } catch (e2) {
            console.error(e2);
          }
        }
        //this.WidthChange(mq);
      }
    },
    WidthChange(mq) {
      this.isMobo = mq.matches;
    },
  },
};
</script>
