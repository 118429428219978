<template>
  <div id="signup-content">
    <section class="py-6 bg-gray-200 text-center pb-0">
      <div class="container">
        <span class="d-inline-grid gap-auto-2 text-danger">
          <span>Account</span>
          <svg
            class="transform-flip-x-rtl"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            ></path>
          </svg>
        </span>

        <h1 class="display-4 fw-bold">Sign up</h1>
      </div>
    </section>
    <div class="divider-point-b bg-gray-200 mt-n4"></div>
    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-md-6 col-lg-5 mx-auto">
            <form
              class="form-validate js-init-validate"
              method="POST"
              action="account-signup-classic.html#"
              novalidate="true"
              @submit.prevent="signUp"
            >
              <div class="form-floating mb-4">
                <input
                  required=""
                  type="text"
                  class="form-control"
                  id="account_first_name"
                  placeholder="First name"
                  v-model="newUser.firstname"
                />
                <label for="account_first_name">First name</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  required=""
                  type="text"
                  class="form-control"
                  id="account_last_name"
                  placeholder="Last name"
                  v-model="newUser.lastname"
                />
                <label for="account_last_name">Last name</label>
              </div>

              <div class="form-floating mb-4">
                <input
                  required=""
                  type="number"
                  class="form-control"
                  id="phone_number"
                  placeholder="Phone Number"
                  v-model="newUser.phone"
                />
                <label for="phone_number">Phone Number</label>
              </div>

              <div class="form-floating mb-4">
                <input
                  required=""
                  type="email"
                  class="form-control"
                  id="account_email"
                  placeholder="Email address"
                  v-model="newUser.email"
                />
                <label for="account_email">Email address</label>
              </div>

              <div class="form-floating mb-4">
                <input
                  required=""
                  type="password"
                  class="form-control"
                  id="account_passwd"
                  placeholder="Password"
                  autocomplete="new-password"
                  v-model="newUser.password"
                />
                <label for="account_passwd">Password</label>
                <router-link
                  :to="{ name: ''}"
                  class="
                    btn
                    bg-transparent
                    shadow-none
                    link-muted
                    position-absolute
                    top-0
                    end-0
                    m-2
                  "
                >
                  <span class="group-switch">
                    <svg
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <svg
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
                      ></path>
                    </svg>
                  </span>
                </router-link>
              </div>

              <!-- agree -->
              <label class="d-flex align-items-center mb-4">
                <input
                  required=""
                  class="d-none-cloaked"
                  type="checkbox"
                  name="switch-checkbox"
                  value="1"
                />
                <i class="switch-icon switch-icon-primary"></i>
                <span class="px-3 user-select-none"
                  >I agree
                  <router-link
                    :to="{ name: ''}"
                    target="_blank"
                    class="link-muted text-dashed"
                    >terms &amp; conditions</router-link
                  ></span
                >
              </label>

              <!-- submit button -->
              <div class="d-grid mb-4">
                <div v-if="!empty(validationErrors)" class="mb-3">
                  <ul class="alert alert-danger">
                    <li
                      v-for="(value, key, index) in validationErrors"
                      :key="'error' + key + index"
                    >
                      {{ value }}
                    </li>
                  </ul>
                </div>

                <wolf-button
                  @btn-role="signUp"
                  classes="btn btn-danger pe-none-childs"
                  :disabler="!newUser.firstname || !newUser.password"
                  activator="submittingRequest"
                >
                  <span slot="caption">
                    <span>Sign up</span>
                    <svg
                      class="transform-flip-x-rtl"
                      height="18px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                      ></path>
                    </svg>
                  </span>
                </wolf-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Register",
  data: () => ({
    newUser: {
      firstname: null,
      lastname: null,
      email: null,
      accountType: 1,
      password: null,
      terms: 0,
      phone: null,
    },
    emailSent: false,
    emailError: false,
    phoneError: false,
    errorsMessages: {},
  }),
  computed: {
    validationErrors() {
      let errors = Object.values(this.errorsMessages);
      errors = errors.flat();
      return errors;
    },
  },
  methods: {
    signUp() {
      this.$store.dispatch("logout").then(() => {
        this.$store
          .dispatch("authRequest", {
            formData: this.formData(this.newUser),
            url: "auth/register",
          })
          .then((response) => {
            if (response.data.status) {
              location.replace("/admin/dashboard");
            }
          });
      });
    },
  },
};
</script>
