<template>
  <div id="login-content">
    <section class="py-6 bg-gray-200 text-center pb-0">
      <div class="container">
        <span class="d-inline-grid gap-auto-2 text-danger">
          <span>Account</span>
          <svg
            class="transform-flip-x-rtl"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            ></path>
          </svg>
        </span>

        <h1 class="display-4 fw-bold">Sign in</h1>
      </div>
    </section>
    <div class="divider-point-b bg-gray-200 mt-n4"></div>
    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-md-6 col-lg-5 mx-auto">
            <form
              class="form-validate js-init-validate"
              method="POST"
              action="account-signin-classic.html#"
              novalidate="true"
              @submit.prevent="logUser"
            >
              <!-- email address -->
              <div class="form-floating mb-4">
                <input
                  required=""
                  type="email"
                  class="form-control"
                  id="account_email"
                  placeholder="Email address"
                  v-model="credentials.email"
                />
                <label for="account_email">Email address</label>
              </div>

              <!-- password -->
              <div class="form-floating mb-4">
                <input
                  required=""
                  type="password"
                  class="form-control"
                  id="account_passwd"
                  placeholder="Password"
                  autocomplete="new-password"
                  v-model="credentials.password"
                />
                <label for="account_passwd">Password</label>

                <!-- forgot -->
                <router-link
                  :to="{ name: '' }"
                  class="
                    btn
                    bg-transparent
                    shadow-none
                    link-muted
                    position-absolute
                    top-0
                    end-0
                    m-1
                  "
                >
                  Forgot
                </router-link>
              </div>
              <div class="alert alert-danger my-2" v-if="invalidLogin">
                <span>Invalid password or Email addess</span>
              </div>
              <div
                class="alert alert-success fade show my-2"
                role="alert"
                v-if="loginSucceed"
              >
                <strong>Success!</strong> Login Success. Redirecting...
              </div>
              <div class="d-grid mb-4">
                <wolf-button
                  @btn-role="logUser"
                  classes="btn btn-danger pe-none-childs"
                  :disabler="!credentials.email || !credentials.password"
                  activator="submittingRequest"
                >
                  <span slot="caption">
                    <span>Sign in</span>
                    <svg
                      class="transform-flip-x-rtl"
                      height="18px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                      ></path>
                    </svg>
                  </span>
                </wolf-button>
              </div>
            </form>

            <!-- create account -->
            <div class="text-center">
              <router-link :to="{ name: 'Register' }" class="link-muted">
                Don't have an account yet?
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    credentials: {
      email: null,
      password: null,
    },
    invalidLogin: false,
    loginSucceed: null,
  }),
  methods: {
    logUser() {
      this.invalidLogin = false;
      this.loginSucceed = false;
      this.$store
        .dispatch("authRequest", {
          formData: this.formData(this.credentials),
          url: "auth/login",
        })
        .then((response) => {
          if (response.data.status) {
            this.invalidLogin = false;
            this.loginSucceed = true;
            location.replace("/admin/dashboard");
          } else {
            this.invalidLogin = true;
            this.loginSucceed = false;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.invalidLogin = true;
            this.loginSucceed = false;
          }
        });
    },
  },
};
</script>
