<template>
  <div class="landing-wrapper">
    <div
      class="MuiPaper-root MuiPaper-elevation0 MuiPaper-rounded"
      v-if="banners.length"
    >
      <div class="jss170 jss169">
        <carousel
          :items="1"
          :autoplay="true"
          :loop="true"
          :dots="false"
          :nav="false"
          :autoplayTimeout="8000"
        >
          <div
            class="CarouselItem"
            v-for="(banner, i) in banners"
            :key="'banner' + i"
          >
            <a
              class="
                MuiTypography-root
                MuiLink-root MuiLink-underlineHover
                MuiTypography-colorPrimary
                jss34
              "
              href="javascript:void(0)"
              ><div style="width: 100%; display: flex; justify-content: center">
                <div
                  :style="`color: white;
                    width: 100%;
                    max-width: 1600px;
                    position: relative;
                    font-size: 8.44375px;
                    background-image: url(${apiURL + '/' + banner.banner_image})
                      `"
                  class="py-5"
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-xxl-12">
                        <div class="slider__content text-center">
                          <h2
                            class="slider__title"
                            style="animation-delay: 0.4s; text-decoration: none"
                          >
                            {{ banner.title }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></a
            >
          </div>

          <template slot="prev">
            <div class="jss174 jss175 jss181">
              <button
                class="
                  MuiButtonBase-root
                  MuiIconButton-root
                  jss176
                  jss178
                  jss177
                  jss181
                "
                tabindex="0"
                type="button"
              >
                <span class="MuiIconButton-label"
                  ><svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
                    ></path></svg></span
                ><span class="MuiTouchRipple-root"></span>
              </button>
            </div>
          </template>
          <template slot="next">
            <div class="jss174 jss175 jss180">
              <button
                class="
                  MuiButtonBase-root
                  MuiIconButton-root
                  jss176
                  jss178
                  jss177
                  jss180
                "
                tabindex="0"
                type="button"
                aria-label="Next"
              >
                <span class="MuiIconButton-label"
                  ><svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                    ></path></svg></span
                ><span class="MuiTouchRipple-root"></span>
              </button>
            </div>
          </template>
        </carousel>
      </div>
    </div>
    <section
      class="
        services__area services__overlay
        p-relative
        z-index-11
        pt-50
        pb-555
      "
    >
      <div class="container">
        <div class="row">
          <div
            class="
              col-xxl-8
              offset-xxl-2
              col-xl-8
              offset-xl-2
              col-lg-10
              offset-lg-1
            "
          >
            <div class="section__title-wrapper mb-80 text-center">
              <span class="section__title-pre center">What We Offer</span>
              <h2 class="section__title">
                We are Qualified &amp; Professional
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-4 col-xl-4 col-lg-6 col-md-6"
            v-for="(service, i) in services"
            :key="'service' + i"
          >
            <div class="services__item services__item-2 p-relative mb-30">
              <div class="services__thumb w-img">
                <img
                  :src="apiURL + '/' + service.image"
                  style="height: 246px"
                  alt=""
                />
              </div>
              <div class="services__content services__content-2 text-center">
                <div class="services__icon services__icon-2">
                  <img
                    class="img-fluid flaticon-paint-1"
                    :src="`${publicPath}img/logo-icon.png`"
                    alt=""
                  />
                </div>
                <h3 class="services__title">
                  <router-link :to="{ name: '' }">{{
                    service.title
                  }}</router-link>
                </h3>
                <p class="two-lines">{{ service.description }}</p>
                <router-link :to="{ name: '' }" class="link-btn-2">
                  read more
                  <i class="far fa-arrow-right"></i>
                  <i class="far fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="why__area pt-130 pb-130">
      <div class="container">
        <div class="row">
          <div class="col-xxl-7 col-xl-6 col-lg-6">
            <div class="why__thumb why__thumb-2 pr-70 w-img">
              <img :src="apiURL + '/' + values.image" alt="" />
            </div>
          </div>
          <div class="col-xxl-5 col-xl-6 col-lg-6">
            <div class="why__wrapper-2 pt-25 pr-30">
              <div class="section__title-wrapper-2 mb-30">
                <h2 class="section__title section__title-2">
                  {{ values.title }}
                </h2>
              </div>
              <span class="section__title-pre section__title-pre-2"
                  >Values</span
                >
              <p>
                {{ values.summary }}
              </p>

              <div class="why__item-wrapper">
                <div
                  class="why__item-2 d-flex align-items-start"
                  v-for="(section, i) in values.sections"
                  :key="'section' + i"
                >
                  <div class="why__icon-2 mr-30"></div>
                  <div class="why__content-2">
                    <h3 class="why__title-2">{{ section.title }}</h3>
                    <p>
                      {{ section.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="team__area pt-125 pb-95">
      <div class="container">
        <div class="row">
          <div
            class="
              col-xxl-6
              offset-xxl-3
              col-xl-8
              offset-xl-2
              col-lg-8
              offset-lg-2
            "
          >
            <div class="section__title-wrapper-2 mb-80 text-center">
              <span class="section__title-pre section__title-pre-2 center"
                >Our Team</span
              >
              <h2 class="section__title section__title-2">
                Meet The Expert Team
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-3 col-xl-3 col-lg-3 col-md-4"
            v-for="(member, i) in members"
            :key="'member' + i"
          >
            <div class="team__item white-bg text-center mb-30">
              <div class="team__thumb w-img fix">
                <img
                  :src="apiURL + '/' + member.avatar"
                  v-if="member.avatar"
                  class="img-fluid"
                  alt=""
                />
                <img
                  :src="`${publicPath}img/avatar.png`"
                  v-else
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="team__content">
                <h3 class="team__title">
                  <a href="javascript:void(0)">{{ member.name }}</a>
                </h3>
                <span class="post">{{ member.title }}</span>
                <div class="team__social" v-if="false">
                  <ul>
                    <li>
                      <a :href="member.facebook_url || 'javascript:void(0)'">
                        <svg
                          class="text-muted"
                          width="16px"
                          height="16px"
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42v-78.89s-36.68-6.26-71.75-6.26c-73.22 0-121.08 44.38-121.08 124.72v70.62h-81.39v92.66h81.39v224h100.17v-224z"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><i class="fab fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><i class="fab fa-youtube"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><i class="fab fa-linkedin"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Index",
  components: { carousel },
  data: () => ({
    banners: [],
    services: [],
    members: [],
    values: {},
  }),
  updated() {},
  beforeCreate() {
    this.$store.dispatch("getRequest", "ghost/home-page").then((response) => {
      if (response.data.status) {
        this.banners = response.data.banners;
        this.services = response.data.services;
        this.members = response.data.members;
        this.values = response.data.values[0];
      }
    });
  },
  methods: {},
  directives: {},
};
</script>
<style scoped>
.four-lines {
  line-height: 20px;
  color: #333;
  letter-spacing: 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 80px;
  -webkit-line-clamp: 4;
}
.two-lines {
  line-height: 20px;
  height: 30px;
  -webkit-line-clamp: 2;
}
</style>
<style lang="css">
span[id^=carousel_prev_]{
  display: block !important
}
.CarouselItem {
  position: relative;
}
.CarouselItem::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(31, 43, 58, 0.6);
  z-index: 1;
  animation: overlay-fade-in 0.4s ease 1;
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
