<template>
  <main>
    <!-- page title area start -->
    <section
      class="page__title-area pt-160 pb-165 p-relative page__title-overlay"
      data-background="assets/img/page-title/page-title-1.jpg"
      style="background-image: url('assets/img/page-title/page-title-1.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="page__title-wrapper">
              <h3 class="page__title">Contact Us</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->

    <!-- contact area start -->
    <section class="contact__area contact__padding pt-70 pl-55 pr-55">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xxl-4 col-xl-4">
            <div
              class="contact__item green-bg-2 d-sm-flex align-items-start mb-30"
            >
              <div class="contact__icon mr-5">
                <i class="far fa-map-marker-alt text-white"></i>
              </div>
              <div class="contact__content">
                <h5>Main Location</h5>
                <p>
                  <a target="_blank" href="javascript:void(0)">{{
                    settings.social.address
                  }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4">
            <div
              class="contact__item green-bg-2 d-sm-flex align-items-start mb-30"
            >
              <div class="contact__icon mr-5">
                <i class="far fa-envelope-open text-white"></i>
              </div>
              <div class="contact__content">
                <h5>Email Address</h5>
                <p>
                  <a :href="`mailto:${settings.social.email}`">{{
                    settings.social.email
                  }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4">
            <div
              class="contact__item green-bg-2 d-sm-flex align-items-start mb-30"
            >
              <div class="contact__icon mr-5">
                <i class="far fa-phone-alt text-white"></i>
              </div>
              <div class="contact__content">
                <h5>Phone Number</h5>
                <p>
                  <a :href="`tel:${settings.social.phone}`">{{
                    settings.social.phone
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- contact area end -->

    <!-- contact area start -->
    <section class="contact__area pt-90 pb-130">
      <div class="container">
        <div class="row">
          <div
            class="
              col-xxl-8
              offset-xxl-3
              col-xl-8
              offset-xl-3
              col-lg-10
              offset-lg-2
              col-md-10
              offset-md-1
            "
          >
            <div class="section__title-wrapper mb-70 text-center">
              <span class="section__title-pre section__title-pre-3 center"
                >Get In Touch</span
              >
              <h2 class="section__title section__title-2">
                Don’t Hesitate To Contact Autoplus Rwanda
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-12">
            <div class="contact__wrapper-4">
              <div class="contact__form-3 contact__form-4">
                <form action="" @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div class="contact__input-3">
                        <input
                          type="text"
                          placeholder="Your Full Name"
                          v-model="newRequest.name"
                        />
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div class="contact__input-3">
                        <input
                          type="email"
                          placeholder="Your Email Address"
                          v-model="newRequest.email"
                        />
                        <i class="far fa-envelope"></i>
                      </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div class="contact__input-3">
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          v-model="newRequest.phone_number"
                        />
                        <i class="far fa-phone"></i>
                      </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div class="contact__input-3">
                        <select v-model="newRequest.service">
                          <option :value="null">Select Services</option>
                          <option
                            v-for="(service, i) in services"
                            :key="'service' + i"
                            :value="service.title"
                          >
                            {{ service.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div class="contact__input-3">
                        <select v-model="newRequest.product">
                          <option :value="null">Select Product</option>
                          <option
                            v-for="(product, i) in products"
                            :key="'product' + i"
                            :value="product.title"
                          >
                            {{ product.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                      <div class="contact__input-3">
                        <textarea
                          placeholder="Message"
                          v-model="newRequest.message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                      <div class="mb-3" v-if="success">
                        <div class="alert alert-success" role="alert">
                          <strong>Success!</strong> Your request has been
                          submitted now. We will contact you soon
                        </div>
                      </div>
                      <div class="contact__btn-3 contact__btn-4">
                        <button
                          type="button"
                          class="r-btn r-btn-yellow w-100"
                          @click="handleSubmit"
                          :disabled="shouldNotSubmit"
                        >
                          <template
                            v-if="$store.state.activeBtn == 'sendingMessage'"
                          >
                            <p id="loading-btn">
                              <span></span><span></span><span></span>
                            </p>
                          </template>
                          <template v-else>
                            send message <i class="far fa-arrow-right"></i>
                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Contact",
  data: () => ({
    newRequest: {
      name: null,
      email: null,
      phone_number: null,
      address: null,
      message: null,
      service: null,
      product: null
    },
    success: false,
    services: [],
    products: [],
  }),
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
    shouldNotSubmit() {
      return (
        !this.newRequest.name ||
        !this.newRequest.email ||
        !this.newRequest.message
      );
    },
  },
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `ghost/contact/assets`)
      .then((response) => {
        if (response.data.status) {
          this.services = response.data.services;
          this.products = response.data.products;
        }
      });
  },
  methods: {
    handleSubmit() {
      this.success = false;
      this.$store.state.activeBtn = "sendingMessage";
      this.$store
        .dispatch("postRequest", {
          formData: this.formData(this.newRequest),
          url: "ghost/requests/add",
        })
        .then((response) => {
          if (response.data.status) {
            this.clearObject(this.newRequest);
            this.success = true;
          }
        });
    },
  },
};
</script>
<style scoped>
.contact__content p {
  font-size: 20px;
  line-height: 24px;
}
.contact__content h5 {
  margin-bottom: 13px;
}

.contact__input-3 select {
  width: 100%;
  font-size: 18px;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.1);
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #ffffff;
  height: 80px;
  line-height: 76px;
  color: #2c3941;
  background: #f8f4ef;
  border-color: #f8f4ef;
  padding: 0 35px;
  padding-right: 70px;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#loading-btn {
  text-align: center;
  margin: 0;
}

#loading-btn span {
  display: inline-block;
  width: 0.6125rem;
  height: 0.6125rem;
  margin: 0 0.3125rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  vertical-align: middle;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;

  -moz-animation-name: opacity;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
}

#loading-btn span:nth-child(2) {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
}

#loading-btn span:nth-child(3) {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
}
button:disabled {
  cursor: not-allowed;
}
</style>
